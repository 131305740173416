import { useEffect } from "react";

declare global {
  interface Window {
    GDemo: any;
  }
}

const runDemo = () => {
  if (typeof window.GDemo !== "function") {
    setTimeout(() => runDemo(), 100);
    return;
  }

  const demo = new window.GDemo("#demo");
  const hiveId = "369e450712540770bd9a7bf0b8a7efc9";

  demo
    .openApp("terminal", { minHeight: 500 })
    .command("curl -X POST https://trakr.dev/api/track \\", {
      promptString: "$",
    })
    .command(`-H "Content-Type: application/json" \\`, {
      promptString: ">",
    })
    .command(`-H "X-API-KEY: ${hiveId.slice(0, 3)}..." \\`, {
      promptString: ">",
    })
    .command(
      `--data '${JSON.stringify(
        {
          event: "pageView",
          tags: { page: "home" },
        },
        null,
        2
      )}'`,
      {
        onCompleteDelay: 500,
        promptString: ">",
      }
    )
    .respond(
      JSON.stringify(
        {
          success: true,
        },
        null,
        2
      )
    )
    // get data
    .command(
      `curl -X GET https://trakr.dev/api/fetch?event=pageView&tags[page]=home&timestamp=${
        Date.now() - 10000
      } \\`,
      {
        promptString: "$",
      }
    )
    .command(`-H "X-API-KEY: ${hiveId.slice(0, 3)}..." \\`, {
      onCompleteDelay: 500,
      promptString: ">",
    })
    .respond(
      JSON.stringify(
        {
          data: [
            {
              event: "pageView",
              timestamp: new Date(Date.now() - 70000).toISOString(),
              tags: { page: "home" },
            },
            {
              event: "pageView",
              timestamp: new Date().toISOString(),
              tags: { page: "about" },
            },
          ],
        },
        null,
        2
      )
    )
    .command("", { onCompleteDelay: 2000, promptString: "$" })
    .end();
};

const Demo = () => {
  useEffect(() => {
    runDemo();
  }, []);

  return <div id="demo" className="w-full" />;
};

export default Demo;
