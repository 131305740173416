import type { NextPage } from "next";
import Image from "next/image";

import Demo from "./components/demo";

const Home: NextPage = () => {
  return (
    <div className="flex flex-col md:flex-row items-start max-w-screen-xl min-h-screen py-10 mx-auto relative justify-items-start px-8">
      <div className="text-center md:text-left w-full md:w-1/2 lg:w-1/3 xl:w-1/4 max-w-sm self-center md:self-auto md:pt-8 md:mr-8">
        <Image
          src="/images/logo-white.png"
          alt=""
          height="100"
          width="53.7"
          className="mb-3"
        />

        <div className="flex flex-col">
          <p className="text-xl">Trakr Jackr</p>
          <p className="pb-4 underline underline-offset-2">
            Simple event storage for the rest of us
          </p>
          <p className="text-sm">
            Free for up to 1 event per second; charts, triggers, and more coming
            soon
          </p>
        </div>

        <form className="pt-4 pb-8" action="/api/register" method="GET">
          <input
            type="email"
            className="my-4 rounded px-4 py-2 w-full text-black"
            placeholder="dev@app.co"
            required={true}
            name="email"
          />
          <button type="submit" className="mb-2 ml-1 hover:underline">
            Get Started
          </button>
        </form>

        <div className="text-sm pb-8">
          Sounds too good to be true?
          <br />
          We can help:{" "}
          <a href="mailto:hey@trakr.dev" className="underline">
            hey@trakr.dev
          </a>
        </div>
      </div>

      <div className="w-full md:w-1/2 lg:w-2/3 xl:w-3/4 xl:pl-8 py-4 max-w-full">
        <Demo />
      </div>

      <div className="absolute bottom-4 text-sm w-full text-center md:text-left left-0 md:pl-8 hidden md:block">
        &copy; {new Date().getFullYear()}{" "}
        <a
          href="https://bridgetowncollective.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bridgetown Collective
        </a>
      </div>

      <link
        rel="stylesheet"
        href="https://unpkg.com/@glorious/demo/dist/gdemo.min.css"
      />
      <script
        src="https://unpkg.com/@glorious/demo/dist/gdemo.min.js"
        defer
      ></script>
    </div>
  );
};

export default Home;
